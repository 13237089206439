import * as React from "react"
import bgWave from "../images/bg-wave1.svg"
import iconTick from "../images/icon-tick.svg"
import {CheckCircleIcon } from '@heroicons/react/outline'
import icon_b from "../images/icon-brochure.svg"
import pdf from "../assets/CarbonPass Brochure.pdf"
import { Link } from "gatsby"
const features = [
    {
      name: 'Actual calculation',
      description:
        'Calculated based on verified aviation data, applied to the actual route and specific to the aircraft type servicing the selected route',
      icon: CheckCircleIcon,
    },
    {
      name: 'Balancing your business',
      description:
        "Provide businesses with a tool to quantify and balance their employees' and business partners' travel emissions generated through your airport",
      icon: CheckCircleIcon,
    },
    {
      name: 'Scope three emissions',
      description:
        "A solution for your customers to contribute to balancing your airport's scope three emissions",
      icon: CheckCircleIcon,
    },
    {
      name: 'Local community',
      description:
        'Customer contributions go to balancing schemes that are chosen by the host airport, so they are right and meaningful for you, your passengers and your local community',
      icon: CheckCircleIcon,
    },
  ]

const CarbonPassDifferent = () => {
    return (
        <>

<div className="pb-12 -translate-y-10 overflow-x-hidden overflow-y-hidden" style={{backgroundImage: `url(${bgWave})`,  backgroundRepeat:"no-repeat", backgroundPosition:"top center"}}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:py-20 py-8 mt-0">
        <div className="lg:text-center">
        <div className="scrollTop">
          <h2 className="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">
          Why is CarbonPass different?
          </h2>
          </div>
          <div className="scrollTop">
          <p className="mt-4 w-5/6 md:w-3/4  text-xl text-gray-500 lg:mx-auto ">
          There are platforms that enable you to calculate your emissions by differing methodologies and pay into carbon offsetting schemes. CarbonPass has four important differences:
          </p> 
          </div>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (

              <div key={feature.name} className="relative">
                
                <dt>
                <div className="fadeLeft">
                  <div className="absolute flex items-center justify-center h-12 w-12 text-white">
                  <img src={iconTick} alt="tick icon"></img>
                  </div>
                  </div>
                  <div className="fadeIn">
                  <h3 className="ml-16 leading-6  text-xl font-medium text-gray-900">{feature.name}</h3>
                  </div>
                </dt>
                <div className="fadeIn">
                <dd className="mt-2 ml-16 text-xl text-gray-500">{feature.description}</dd>
  </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
<div className="bg-carbongreen  -mt-10" >
<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 lg:py-20 mt-0 bg-local"  style={{backgroundImage: `url(${icon_b})`,  backgroundRepeat:"no-repeat", backgroundPosition:"bottom -200px right", backgroundSize:"400px 400px"}}>
<div className="scrollTop">
<h2 className="text-2xl leading-8 font-bold tracking-tight text-white sm:text-4xl mb-8">
        Download the CarbonPass brochure and learn more today!
          </h2>
          </div>
          <a href={pdf} target="_blank"><button className="text-2xl lg:mb-8 mb-32 bg-carbonblue rounded-lg py-2 px-10 transition duration-500 ease-in-out bg-blue-500 hover:bg-black transform hover:-translate-y-1 hover:scale-110 text-white">Download</button></a>
  </div>
</div>

        </>
    )
}

export default CarbonPassDifferent