import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CarbonPassHeader from "../components/carbonpassheader2"
import CarbonPassDifferent from "../components/carbonpassdifferent"
import CaseStudy from "../components/casestudy"
import GetInvolved from "../components/getinvolved2"
import GetInTouch from "../components/getintouch"
import CarbonPassCard from "../components/carbonpasscard"
import Main from "../components/main"


const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <CarbonPassHeader/>
    <Main/>
    <CarbonPassCard/>
    <CarbonPassDifferent/>
    <CaseStudy/>
    <GetInvolved/>
    <GetInTouch/>
  </Layout>
)

export default IndexPage
