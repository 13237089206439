import * as React from "react"

const CaseStudy = () => {
    return (
        <>


    <div className="bg-carbonblue -translate-y-10 lg:py-16 py-8 mb-0 overflow-x-hidden overflow-y-hidden">
      <div className="max-w-7xl mx-auto px-4 md:px-4 lg:px-8">
      <div className="text-center">
      <div className="scrollTop">
          <h2 className="mt-2 text-3xl leading-8 font-bold tracking-tight text-white sm:text-4xl mb-8">
          Case study
          </h2>
          </div>
          <div className="block w-1/3 border-t border-white mx-auto"></div>
        </div>

        <div className="mt-10">
          <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">

    

              <div className="relative">
                <div className="mt-2 ">
                <iframe className="w-full h-72 pr-1" src="https://www.youtube.com/embed/BTnSsB-_-z8" title="Carbon Pass YouTube Video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>

              <div className="relative">
                <div>

                <div className="fadeIn">
                  <p className="mt-2 font-medium text-white text-3xl mb-8">Jersey Airport</p>
</div> <div className="fadeIn">
            <p className="text-xl w-full text-white">In March 2022, Jersey Airport launched 
its Sustainability Plan and they wanted to 
give their passengers an easy way to get 
involved and contribute. They wanted to 
provide their passengers with the option 
to balance their travel carbon emissions 
should they wish. <br/>
CarbonPass has been deployed via 
app, kiosks and our website to make 
it accessible to all. Kiosk areas have 
been set up in the airport terminal 
with interactive displays to encourage 
on-site, immediate engagement. 
</p></div>

                </div>
              </div>

          </div>
        </div>
      </div>
    </div>

    </>
    )
}

export default CaseStudy