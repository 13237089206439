import * as React from "react"
import { Link } from "gatsby"
import bgForest from "../images/bg-forest.jpg"
import bgMobile from "../images/bg-mobile.png"

import qrCode from "../images/QR-code.svg"
import carbonPass from "../images/CarbonPass-logo.svg"
import appStore from "../images/app-store-apple-logo.svg"
import bgRightCloud from "../images/bg-top-right-cloud.svg"
import bgLeftCloud from "../images/bg-bottom-left-cloud.svg"


const Main = () => {
    return (
        <>
           <main className="relative">
      <div id="MainBanner" className="grid sm:grid-cols-1  lg:grid-cols-2 text-white sm:h-full lg:h-screen z-0  md:mt-0 md:mb-8 lg:-mt-32 lg:-mb-8 ">
     
     <div className="bg-carbonblue h-full relative  lg:pt-48 sm:pt-18  sm:pm-18">
    
     <div style={{backgroundImage: `url(${bgRightCloud})`,backgroundSize: 'auto',backgroundAttachment:'cover',backgroundRepeat:'no-repeat', width:"24vh",height:"18vh"}} className="absolute right-0 lg:top-32 hidden lg:block z-10">
 
     </div>
     <div className="h-auto lg:h-full sm:py-8 z-30" >
     <a href="https://apps.apple.com/gb/app/carbonpass/id1613326988"><img className="mx-auto w-1/5 mt-8" src={qrCode}/>
     <img className="mx-auto w-1/5" src={appStore}/>
    <img className="mx-auto w-2/3" src={carbonPass}/></a>
     <div className="text-center sm:pb-24">
     <Link to="details"><button className="text-2xl mb-8 text-white bg-carbongreen rounded-lg py-2 px-10 mt-10 transition duration-500 ease-in-out bg-blue-500 hover:bg-black transform hover:-translate-y-1 hover:scale-110 ">Learn more</button> </Link>
     </div>
     </div>
     <div style={{backgroundImage: `url(${bgLeftCloud})`,backgroundSize: 'auto',backgroundAttachment:'cover',backgroundRepeat:'no-repeat', width:"32vh",height:"18vh"}} className="absolute left-0 hidden lg:block z-10 bottom-1 lg:bottom-8 ">
    
     </div>
     
     </div>
 
     <div id="HandMobile"  style={{backgroundImage: `url(${bgForest})`,backgroundSize: 'cover',backgroundAttachment:'fixed'}} className="bg-carbongreen h-full overflow-hidden lg:pt-28 ">
      <div className="mx-auto block relative h-full ">
          <img className="absolute right-0 lg:bottom-2 sm:bottom-0" src={bgMobile} alt="app screen"/></div>
     </div>
 
     </div> 
      </main>
    </>
    )
}

export default Main
