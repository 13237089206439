import * as React from "react"
import { Link } from "gatsby"
import bgMobile2 from "../images/bg-mobile2.png"
import bgWave2 from "../images/bg-wave2.svg"

const GetInvolved = () => {
    return (
        <>
            <div className=" bg-white -mb-10">
      <div className="max-w-7xl mx-auto">
        <div>
          <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10  sm:px-6 lg:px-8 mx-4">

              <div>
              <div className="scrollTop">
              <h2 className=" mb-6 mt-4 text-4xl lg:mt-12 lg:mb-8 leading-8 font-bold tracking-tight text-gray-900 ">Get Involved</h2>
 </div> <div className="fadeIn">
            <p className="text-xl font text-gray-900">For more information, to discuss 
your needs or if you have any further 
questions, please don't hesitate to 
contact us on <a href="mailto:sales@CarbonPass.com" className="">sales@CarbonPass.com</a>. 
We hope to hear from you.</p>
           </div> <br/>
           <a href="mailto:sales@CarbonPass.com" ><button className="text-2xl mb-0 lg:mb-8 bg-carbongreen rounded-lg mt-3 py-2 px-10 mt-8 transition duration-500 ease-in-out bg-blue-500 hover:bg-black transform hover:-translate-y-1 hover:scale-110 text-white">Contact us</button></a>


              </div>

                <div className="h-full -translate-y-10 py-6"  style={{backgroundImage: `url(${bgWave2})`, backgroundSize: 'cover',backgroundAttachment:'fixed'}}>

            <img src={bgMobile2} className="w-3/4 " alt="mobile app screen"/>
            </div>

          </div>
        </div>
      </div>
    </div>




    </>
    )
}

export default GetInvolved