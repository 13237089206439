import * as React from "react"
import {useEffect} from "react"
import iconPass from "../images/icon-pass.svg"
import { scrollFadeIn,scrollTop,fadeLeft } from "./showonscroll";

const CarbonPassCard = () => {
  useEffect(() => {
    scrollFadeIn();
    scrollTop();
    fadeLeft();
  }, []);

    return (
        <>
    <div className="bg-carbongreen text-white text-center mx-6 mt-6 -translate-y-10 py-10 overflow-x-hidden overflow-y-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-8">
      <div className="lg:text-center mt-8 ">
      <div className="scrollTop">
          <h2 className="mt-2 text-3xl leading-8 font-bold tracking-tight text-white sm:text-4xl mb-8">
          A ready-made bespoke solution local to you 
          </h2></div>
          <div className="block w-1/3 border-t border-white mx-auto"></div>
        </div>

        <div className="mt-10">
          <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">

    

              <div className="relative">
              <div className="text-right">
                <img className="mx-auto w-80" src={iconPass} alt="clipart pass"/>
                </div>
              </div>

              <div className="relative">
                <div className="text-left">
                <div className="scrollTop">
                  <h2 className="text-3xl leading-8 font-bold tracking-tight text-white sm:text-4xl my-8">What is CarbonPass?</h2>
</div>  <div className="fadeIn">
            <p className="text-md text-xl text-white">CarbonPass is a white-label solution, that enables your customers to balance the carbon they create by their air travel. It provides passengers with information on the exact amount of carbon produced by their travel plans and enables them to pay to balance the carbon through a recognised scheme of your choice</p>
           </div> <br/>
            

                </div>
              
              </div>
          </div>
        </div>
      </div>
    </div>

    </>
    )
}

export default CarbonPassCard
