export const scrollFadeIn = () => {
    const callback = function (entries) {
      entries.forEach((entry) => {
        //console.log(entry);
        if (entry.isIntersecting) {
          entry.target.classList.add("motion-safe:animate-fadeIn");
        } else {
          entry.target.classList.remove("motion-safe:animate-fadeIn");
        }
      });
    };
  
    const observer = new IntersectionObserver(callback);
  
    const targets = document.querySelectorAll(".fadeIn");
    targets.forEach(function (target) {
      target.classList.add("opacity-0");
      observer.observe(target);
    });
    
  };
  export const scrollTop = () => {
    const callback = function (entries) {
      entries.forEach((entry) => {
        //console.log(entry);
        if (entry.isIntersecting) {
          entry.target.classList.add("motion-safe:animate-scrollTop");
        } else {
          entry.target.classList.remove("motion-safe:animate-scrollTop");
        }
      });
    };
  
    const observer = new IntersectionObserver(callback);
  
    const targets = document.querySelectorAll(".scrollTop");
    targets.forEach(function (target) {
      target.classList.add("opacity-0");
      observer.observe(target);
    });
    
  };
  export const fadeLeft = () => {
    const callback = function (entries) {
      entries.forEach((entry) => {
        //console.log(entry);
        if (entry.isIntersecting) {
          entry.target.classList.add("motion-safe:animate-fadeLeft");
        } else {
          entry.target.classList.remove("motion-safe:animate-fadeLeft");
        }
      });
    };
  
    const observer = new IntersectionObserver(callback);
  
    const targets = document.querySelectorAll(".fadeLeft");
    targets.forEach(function (target) {
      target.classList.add("opacity-0");
      observer.observe(target);
    });
    
  };